import {mapRefund, Refund} from './refund.class';
import {ModelBase} from './abstract/model-base';
import {mapOrder, Order} from './order.class';
import {Actor} from './activity-log.class';

export class Payment extends ModelBase {
    public id: string          = null;
    public orderId: string     = null;
    public method: string      = null;
    public status: string      = null;
    public isInstallment       = false;
    public balance: number     = null;
    public currency: string    = null;
    public description: string = null;
    public paymentUrl: string  = null;
    public payedAt: string     = null;
    public refundedAt: string  = null;
    public canceledAt: string  = null;
    public expiredAt: string   = null;
    public failedAt: string    = null;
    public createdAt: string   = null;
    public order: Order        = null;
    public refunds: Refund[]   = [];
    public actor: Actor        = null;

    constructor() {
        super();
        super.setRoutes({
                            getMany: 'v1.0/payment',
                            get:     'v1.0/payment/:id'
                        });
    }

    mapModel(data: any) {
        const model               = new Payment();
        model.id                  = data.id;
        model.orderId             = data.orderId;
        model.method              = data.method;
        model.isInstallment       = data.isInstallment;
        model.status              = data.status;
        model.balance             = data.balance;
        model.currency            = data.currency;
        model.description         = data.description;
        model.paymentUrl          = data.paymentUrl;
        model.payedAt             = data.payedAt;
        model.refundedAt          = data.refundedAt;
        model.canceledAt          = data.canceledAt;
        model.expiredAt           = data.expiredAt;
        model.failedAt            = data.failedAt;
        model.createdAt           = data.createdAt;
        model.order               = data.order ? mapOrder(data.order) : null;
        model.refunds             = data.refunds ? data.refunds.map(refund => mapRefund(refund)) : [];
        model.actor               = data.actor;

        return model;
    }
}

