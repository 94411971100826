import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {environment} from '../../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class PlatformService {
    private account: any;
    private technicalLinkId: string;

    constructor(private http: HttpClient) {
    }

    setAccount(account: any): void {
        this.account = account;
    }

    setTechnicalLinkId(technicalLinkId: any): void {
        this.technicalLinkId = technicalLinkId;
    }

    getAccount(): any {
        if (!this.account) {
            this.account = {
                ID: environment.ACCOUNT_GUID
            };
        }

        return this.account;
    }

    getTechnicalLinkId(): string {
        return this.technicalLinkId;
    }

    getPersons() {
        const config = {
            withCredentials: true,
            params: new HttpParams().set('accountID', this.getAccount().ID)
        };

        return this.http.get<any>(environment.CM_API_PROXY_URL + `/usermanagement/v1.0/memberships`, config);
    }

    public getTicketingTechnicalLinkId(technicalLinks): string {
        return technicalLinks.find(technicalLink => technicalLink.TechnicalLinkTypeID === 'TICKETING')?.ID;
    }
}
