import {DatePipe} from '@angular/common';
import { HttpParams, HttpResponse } from '@angular/common/http';
import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit} from '@angular/core';
import {PageEvent} from '@angular/material/paginator';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {takeUntil, tap} from 'rxjs/operators';
import {Customer} from '../../../shared/models/customer.class';
import {EventTicket} from '../../../shared/models/event-ticket.class';
import {Event} from '../../../shared/models/event.class';
import {Printer} from '../../../shared/models/printer.class';
import {PrintFormat} from '../../../shared/models/printFormat.class';
import {DialogService} from '../../../shared/services/dialog-service/dialog.service';
import {EventService} from '../../../shared/services/event-service/event.service';
import {NotificationService} from '../../../shared/services/notification-service/notification.service';
import {OrderDeliveryService} from '../../../shared/services/order-delivery-service/order-delivery.service';
import {PrintService} from '../../../shared/services/print-service/print.service';
import {
    DialogConfirmationComponent
} from '../../../shared/components/dialogs/dialog-confirmation/dialog-confirmation.component';
import {OrderService} from '../../../shared/services/order-service/order.service';
import {Router} from '@angular/router';
import {CustomerManagementService} from '../../../shared/services/customer-management/customer-management.service';
import {EventManagementService} from '../../../shared/services/event-service/event-management.service';
import {OrganisationSettingIds} from '../../../shared/enums/settings';
import {Selected} from '../../../shared/components/search-filter-container/selected.class';
import {FilterSetting} from '../../../shared/components/search-filter-container/settings.class';
import {AppService} from '../../../app.service';

@Component({
    selector: 'app-event-ticket-list',
    templateUrl: './event-ticket-list.component.html',
    styleUrls: ['./event-ticket-list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class EventTicketListComponent implements OnInit, OnDestroy {
    destroy$: Subject<boolean> = new Subject<boolean>();
    customer$: Observable<Customer> = this.customerManagementService.customer$.pipe(
        tap(customer => this.onCustomerLoad(customer))
    );
    events$: Observable<Event[]>;
    public selectedFilters: Selected = new Selected();
    public filterSettings: FilterSetting[] = [new FilterSetting('EVENT', 'General.Events', true)];

    private search: string;
    private eventsFilter: string[] = [];

    @Input() customer: Customer;
    events: Event[] = [];
    selectedEventTickets: EventTicket[] = [];
    toggle = 'NONE';

    // Send
    emailTo: string;
    smsTo: string;
    firstName: string;
    lastName: string;

    // Printer
    defaultPrinter: Printer;
    printFormats: PrintFormat[] = [];

    // Table
    eventTicketsToggle = false;
    eventTickets$: Observable<EventTicket[]>;
    eventTicketsSubject: BehaviorSubject<any> = new BehaviorSubject(null);
    eventTicketsTableIndex = 0;
    eventTicketsTableTotal = 0;
    eventTicketsTablePageSize = 10;
    eventTicketsTableLoading = true;

    showResell = false;
    showPersonalisation = false;
    isUsingExternalBarcodes = false;

    constructor(private notificationService: NotificationService,
                private datePipe: DatePipe,
                private router: Router,
                private orderService: OrderService,
                private eventService: EventService,
                private eventManagementService: EventManagementService,
                private orderDeliveryService: OrderDeliveryService,
                private printService: PrintService,
                private dialogService: DialogService,
                private changeDetector: ChangeDetectorRef,
                private app: AppService,
                private customerManagementService: CustomerManagementService) {
    }

    ngOnInit() {
        this.eventManagementService.list$.pipe(
            tap(events => this.events = events),
            tap(() => this.changeDetector.markForCheck())
        ).subscribe();

        this.isUsingExternalBarcodes = this.app.config('organisation').getSetting(OrganisationSettingIds.EXTERNAL_TICKETS_ENABLED);

        this.eventTickets$ = this.eventTicketsSubject.asObservable();

        // setup printer
        this.defaultPrinter = this.printService.getDefaultPrinter();
        this.printFormats = this.printService.getPrintFormats();
    }

    ngOnDestroy() {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }

    onCustomerLoad(customer: Customer) {
        this.customer = customer;

        this.emailTo = this.customer.email;
        this.smsTo = this.customer.phoneNumber;
        this.firstName = this.customer.firstName;
        this.lastName = this.customer.lastName;

        this.eventTicketsTableUpdate();
    }

    eventTicketsTableUpdate(pageEvent?: PageEvent) {
        if (!this.customer) {
            return;
        }

        pageEvent = pageEvent ? pageEvent : {pageIndex: 0, pageSize: this.eventTicketsTablePageSize, length: 0};
        this.eventTicketsTableIndex = pageEvent.pageIndex;
        this.eventTicketsTablePageSize = pageEvent.pageSize;
        this.eventTicketsTableLoading = true;
        this.eventTicketsToggle = false;
        let httpParams = new HttpParams()
            .set('venueSectionSeat[sectionName]', 'true')
            .set('eventTicket[customerId]', this.customer.id)
            .set('eventTicket[personalisationCustomer]', 'true')
            .set('eventTicket[guestManager]', 'true')
            .set('eventTicket[scanStatus]', 'true')
            .set('eventTicket[status]', 'ISSUED,BLOCKED,RESELL_AVAILABLE,RESELL_RESERVED,RESELL_SOLD')
            .set('eventTicket[isResellAllowed]', 'true')
            .set('skip', (pageEvent.pageIndex * pageEvent.pageSize).toString())
            .set('take', (pageEvent.pageSize).toString());

        if (this.search) {
            httpParams = httpParams.set('eventTicket[barcode]', this.search);
        }

        if (this.eventsFilter.length > 0) {
            httpParams = httpParams.set('eventTicket[eventIds]', this.eventsFilter.join(','));
        }

        this.eventService.getCustomerEventTickets(this.customer.id, httpParams)
            .pipe(
                takeUntil(this.destroy$)
            )
            .subscribe((response: HttpResponse<EventTicket[]>) => {
                this.showResell = response.body.findIndex(eventTicket => ['RESELL_AVAILABLE', 'RESELL_RESERVED', 'RESELL_SOLD'].includes(eventTicket.status)) >= 0;
                this.showPersonalisation = response.body.findIndex(eventTicket => eventTicket.personalisationCustomerId) >= 0;
                this.eventTicketsTableTotal = parseInt(response.headers.get('X-CM-PAGINATION-TOTAL'), 10);
                this.eventTicketsSubject.next(response.body);
                this.eventTicketsTableLoading = false;
            });
    }

    updateEventTicketSelection(eventTicket?) {
        if (eventTicket && !['RESELL_RESERVED', 'RESELL_SOLD'].includes(eventTicket.status)) {
            const index = this.selectedEventTickets.findIndex(item => {
                return item.id === eventTicket.id;
            }, 0);
            if (index >= 0) {
                this.selectedEventTickets.splice(index, 1);
            } else {
                this.selectedEventTickets.push(eventTicket);
            }
        } else {
            this.eventTicketsSubject.getValue().forEach(eventTicketItem => {
                const index = this.selectedEventTickets.findIndex(item => {
                    return item.id === eventTicketItem.id;
                });

                if (index >= 0 && !this.eventTicketsToggle) {
                    this.selectedEventTickets.splice(index, 1);
                } else if (index < 0 && this.eventTicketsToggle && !['RESELL_RESERVED', 'RESELL_SOLD'].includes(eventTicketItem.status)) {
                    this.selectedEventTickets.push(eventTicketItem);
                }
            });
        }
    }

    eventTicketInSelection(eventTicket) {
        return this.selectedEventTickets.findIndex(item => {
            return item.id === eventTicket.id;
        }) >= 0;
    }

    cancelTickets() {
        const body = [];
        for (const eventTicket of this.selectedEventTickets) {
            const eventTicketBody = Object.assign({}, eventTicket);
            eventTicketBody.status = 'CANCELED';
            body.push(eventTicketBody);
        }

        this.dialogService.createDialogComponent(DialogConfirmationComponent, {
            titleText: {key: 'Dialog.Cancel_Tickets_Title'},
            bodyText: {key: 'Dialog.Cancel_Tickets_Body'},
            cancelText: 'Dialog.Cancel',
            confirmText: 'Dialog.Ok'
        }, 'tickets-cancel');

        this.dialogService.emitDataSubject.subscribe(response => {
            if (!response.cancelled) {
                this.eventService.updateEventTickets(body).subscribe(() => {
                        this.selectedEventTickets = [];
                        this.eventTicketsTableUpdate();
                    }
                );
            }
        });
    }

    blockTickets() {
        const body = [];
        for (const eventTicket of this.selectedEventTickets) {
            const eventTicketBody = Object.assign({}, eventTicket);
            eventTicketBody.status = 'BLOCKED';
            body.push(eventTicketBody);
        }

        this.dialogService.createDialogComponent(DialogConfirmationComponent, {
            titleText: {key: 'Dialog.Block_Tickets_Title'},
            bodyText: {key: 'Dialog.Block_Tickets_Body'},
            cancelText: 'Dialog.Cancel',
            confirmText: 'Dialog.Ok'
        }, 'tickets-block');

        this.dialogService.emitDataSubject.subscribe(response => {
            if (!response.cancelled) {
                this.eventService.updateEventTickets(body).subscribe(() => {
                    this.selectedEventTickets = [];
                    this.eventTicketsTableUpdate();
                    this.notificationService.showTranslatedNotification('success', 'ticket', 'blocked');
                });
            }
        });

    }

    unblockTicket(eventTicket) {
        const body = Object.assign({}, eventTicket);
        body.status = 'ISSUED';

        this.eventService.updateEventTicket(eventTicket.id, body, new HttpParams()
            .set('venueSectionSeat[sectionName]', 'true')
            .set('eventTicket[customerId]', this.customer.id)
            .set('eventTicket[guestManager]', 'true')
            .set('eventTicket[scanStatus]', 'true')
            .set('eventTicket[status]', 'ISSUED,BLOCKED,RESELL_AVAILABLE,RESELL_RESERVED,RESELL_SOLD')
            .set('eventTicket[isResellAllowed]', 'true')).subscribe(eventTicketResult => {
            this.selectedEventTickets = [];
            this.eventTicketsTableUpdate();
            this.notificationService.showTranslatedNotification('success', 'ticket', 'released');
        });
    }

    renewTickets() {
        const confirmBodyText = this.isUsingExternalBarcodes ?
            'Dialog.Renew_Tickets_Barcodes_Body_External' : 'Dialog.Renew_Tickets_Barcodes_Body';
        this.dialogService.createDialogComponent(DialogConfirmationComponent, {
            titleText: {key: 'Dialog.Renew_Tickets_Barcodes_Title'},
            bodyText: {key: confirmBodyText},
            cancelText: 'Dialog.Cancel',
            confirmText: 'Dialog.Ok'
        }, 'tickets-renew');

        this.dialogService.emitDataSubject.subscribe(response => {
            if (!response.cancelled) {
                this.eventService.renewEventTickets(this.selectedEventTickets).subscribe({
                    next: () => {
                        this.selectedEventTickets = [];
                        this.eventTicketsTableUpdate();
                    },
                    error: () => {
                        this.notificationService.showTranslatedError('Ticket_Renewal_Failed');
                    }
                });
            }
        });

    }

    sendTickets(method) {
        const eventTicketBarcodes = this.selectedEventTickets.map((eventTicket) => {
            return eventTicket.barcode;
        }).join(',');

        // TODO: Replace barcodes with event ticket uuids (prevent download every ticket when subscription barcode is the same for each event)
        this.orderDeliveryService.postOrderDelivery({
            method,
            orderItemId: this.selectedEventTickets[0].orderItemId,
            subscriptionId: this.selectedEventTickets[0].subscriptionId,
            barcodes: eventTicketBarcodes,
            phoneNumber: this.smsTo,
            email: this.emailTo,
            firstName: this.firstName,
            lastName: this.lastName
        }).subscribe(orderDelivery => {
            if (orderDelivery.method === 'DOWNLOAD_PDF') {
                (window as any).open(orderDelivery.url, '_blank');
                this.notificationService.showTranslatedNotification('success', 'ticket', 'downloaded');
            } else {
                this.notificationService.showTranslatedNotification('success', 'ticket', 'sent', {
                    isPlural: true
                });
            }
        });
    }

    getCheckInTooltip(scanStatus) {
        return this.datePipe.transform(scanStatus.firstCheckInAt, 'd MMMM y');
    }

    setDefaultPrinter() {
        this.printService.setDefaultPrinter(this.defaultPrinter);
    }

    printerSelected(defaultPrinter, printer) {
        return defaultPrinter && printer && defaultPrinter.id === printer.id && defaultPrinter.name === printer.name && defaultPrinter.type === printer.type;
    }

    printTickets(event?) {
        const eventTicketBarcodes = this.selectedEventTickets.map((eventTicket) => {
            return eventTicket.barcode;
        }).join(',');

        this.printService.print(this.defaultPrinter.type, {
            method: this.defaultPrinter.type,
            // eventId: event.id,
            orderItemId: this.selectedEventTickets[0].orderItemId,
            subscriptionId: this.selectedEventTickets[0].subscriptionId,
            barcodes: eventTicketBarcodes,
            phoneNumber: this.smsTo,
            email: this.emailTo,
            firstName: this.firstName,
            lastName: this.lastName
        });
    }

    goToOrder(eventTicket: EventTicket) {
        this.orderService.getOrders(new HttpParams()
            .set('depth', '1')
            .set('order[orderItemId]', eventTicket.orderItemId ? eventTicket.orderItemId : '')
            .set('order[subscriptionId]', eventTicket.subscriptionId ? eventTicket.subscriptionId : '')
        ).subscribe(response => {
            if (response.body[0]) {
                return this.router.navigate(['/orders', response.body[0].id]);
            }
        });
    }

    getEventById(eventId: string): Event {
        return this.events.find(event => event.id === eventId) ?? null;
    }

    onSearchChange(search: string) {
        this.search = search;
        this.eventTicketsTableUpdate({pageIndex: 0, pageSize: this.eventTicketsTablePageSize, length: 0});
    }

    public onFilterChange(filters: Selected): void {
        this.eventsFilter = filters.eventIds;
        this.eventTicketsTableUpdate({pageIndex: 0, pageSize: this.eventTicketsTablePageSize, length: 0});
    }
}
