<ng-container *ngIf="customer$ | async as customer">
    <div class="page-content form-group">
        <div class="page-section">
            <div class="row">
                <div class="col-12 col-sm-6">
                    <div class="row">
                        <div class="col-12">
                            <div class="card-header">
                                <h3>
                                    {{ ('Customer_Form.Personal_Info' | trans) }}
                                </h3>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 col-md-8">
                            <div class="form-row">
                                <app-form-input formId="customer"
                                                [label]="('Relations.First_Name' | trans)"
                                                [(value)]="customer.firstName">
                                </app-form-input>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 col-md-4">
                            <div class="form-row">
                                <app-form-input formId="customer"
                                                [label]="('Relations.Insertion' | trans)"
                                                [(value)]="customer.insertion">
                                </app-form-input>
                            </div>
                        </div>
                        <div class="col-12 col-md-8">
                            <div class="form-row">
                                <app-form-input formId="customer"
                                                [label]="('Relations.Last_Name' | trans)"
                                                [(value)]="customer.lastName">
                                </app-form-input>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <div class="form-row">
                                <app-form-input formId="customer"
                                                [label]="('Relations.Company_Name' | trans)"
                                                [(value)]="customer.companyName">
                                </app-form-input>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 col-md-4">
                            <div class="form-row">
                                <div class="form-input"
                                     [class.error]="customer.externalReference1 && !customer.gender && !customer.companyName">
                                    <cm-select
                                        data-label="{{('Relations.Gender' | trans)}}"
                                        [value]="customer.gender"
                                        (cm-option-clicked)="customer.gender = $event.detail.value;">

                                        <cm-option slot="option"
                                                   [attr.value]="null"
                                                   [attr.data-display]="('Gender.NA' | trans)">
                                        </cm-option>

                                        <cm-option slot="option"
                                                   [attr.value]="gender.MALE"
                                                   [attr.data-display]="('Gender.MALE' | trans)">
                                        </cm-option>

                                        <cm-option slot="option"
                                                   [attr.value]="gender.FEMALE"
                                                   [attr.data-display]="('Gender.FEMALE' | trans)">
                                        </cm-option>

                                        <cm-option slot="option"
                                                   [attr.value]="gender.OTHER"
                                                   [attr.data-display]="('Gender.OTHER' | trans)">
                                        </cm-option>
                                    </cm-select>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-md-8">
                            <div class="form-row">
                                <app-form-datepicker-v3
                                    formId="customer"
                                    [(value)]="customer.birthday"
                                    [label]="('Customer_Form.Birthday' | trans)"
                                    [dateFormat]="'yyyy-MM-dd'"
                                    [maxDate]="today">
                                </app-form-datepicker-v3>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-sm-1"></div>
                <div class="col-12 col-sm-5">
                    <div class="row">
                        <div class="col-12">
                            <div class="card-header">
                                <h3>{{ ('Customer_Form.Contact_Info' | trans) }}</h3>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 col-sm-12">
                            <div class="form-row">
                                <app-form-input formId="customer"
                                                type="email"
                                                [label]="('Relations.Email' | trans)"
                                                [(value)]="customer.email">
                                </app-form-input>
                            </div>
                        </div>
                        <!-- <ng-container *ngIf="context == 'CUSTOMER_EDIT'">
                            <div class="row">
                                <div class="col-12 col-sm-12 text-center">
                                    <button class="btn btn-secondary"
                                        (click)="resetPassword()">{{('Relations.Send_Password_Reset' | trans)}}</button>
                                </div>
                            </div>
                        </ng-container> -->
                    </div>
                    <div class="row">
                        <div class="col-12 col-sm-12">
                            <div class="form-row">
                                <app-form-input-phone-number formId="customer"
                                                             [label]="('Relations.Phone_Number' | trans)"
                                                             [(value)]="customer.phoneNumber">
                                </app-form-input-phone-number>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <div class="form-row ">
                                <div class="form-input">
                                    <cm-select
                                        data-label="{{('Relations.Language' | trans)}}"
                                        [value]="customer.language"
                                        (cm-option-clicked)="customer.language = $event.detail.value;">
                                        <cm-option slot="option"
                                                   value="EN"
                                                   data-display="{{('Languages.English' | trans)}}">
                                        </cm-option>
                                        <cm-option slot="option"
                                                   value="NL"
                                                   data-display="{{('Languages.Dutch' | trans)}}">
                                        </cm-option>
                                        <cm-option slot="option"
                                                   value="DE"
                                                   data-display="{{('Languages.German' | trans)}}">
                                        </cm-option>
                                        <cm-option slot="option"
                                                   value="FR"
                                                   data-display="{{('Languages.French' | trans)}}">
                                        </cm-option>
                                    </cm-select>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="page-section">
            <div class="row">
                <div class="col-12">
                    <h3>{{ ('Customer_Form.Address_Info' | trans) }}</h3>
                    <div class="row">
                        <div class="col-12 col-sm-6">
                            <div class="form-row">
                                <app-form-input formId="customer"
                                                [label]="('Relations.Street' | trans)"
                                                [(value)]="customer.street">
                                </app-form-input>
                            </div>
                        </div>
                        <div class="col-12 col-sm-6 col-md-2">
                            <div class="form-row">
                                <app-form-input formId="customer"
                                                [label]="('Relations.House_Number' | trans)"
                                                [(value)]="customer.houseNumber">
                                </app-form-input>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 col-sm-6">
                            <div class="form-row">
                                <app-form-input formId="customer"
                                                [label]="('Relations.City' | trans)"
                                                [(value)]="customer.city">
                                </app-form-input>
                            </div>
                        </div>
                        <div class="col-12 col-sm-6 col-md-2">
                            <div class="form-row">
                                <app-form-input formId="customer"
                                                [label]="('Relations.Postal_Code' | trans)"
                                                [(value)]="customer.postalCode">
                                </app-form-input>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 col-sm-6 col-md-4">
                            <div class="form-row ">
                                <div class="form-input">
                                    <cm-select
                                        data-label="{{('Relations.Country' | trans)}}"
                                        [value]="customer.country"
                                        (cm-option-clicked)="customer.country = $event.detail.value;">
                                        <ng-container *ngIf="countries$ | async  as countries">
                                            <ng-container *ngFor="let country of countries">
                                                <cm-option slot="option"
                                                           value="{{country.id}}"
                                                           data-display="{{country.name}}">
                                                </cm-option>
                                            </ng-container>
                                        </ng-container>
                                    </cm-select>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-sm-6 col-md-4">
                            <div class="form-row">
                                <div class="form-input">
                                    <input name="customerProvice" id="customerProvice" class="form-control"
                                           placeholder="{{('Relations.Province' | trans)}}" disabled
                                           [(ngModel)]="customer.province">
                                    <label for="customerProvice">{{ ('Relations.Province' | trans) }}</label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="page-section">
            <div class="row">
                <ng-container *ngIf="!customer.id">
                    <div class="col-12 col-sm-4 text-left cl-error-color">
                        <ng-container *ngIf="showFormInvalidMessage">
                            {{ ('Customer_Form.Required_Fields_Title' | trans) }}<br>
                            {{ ('Customer_Form.Required_Fields' | trans) }}
                        </ng-container>
                    </div>
                    <div class="col-12 col-sm-8 text-right">
                        <button class="btn btn-secondary m-r-8" (click)="cancelCustomer()">
                            {{ ('General.Cancel' | trans) }}
                        </button>
                        <button class="btn btn-cta"
                                (click)="saveCustomer(customer)">{{ ('General.Save' | trans) }}
                        </button>
                    </div>
                </ng-container>
                <ng-container *ngIf="customer.id">
                    <div class="col-12 col-sm-4 text-left">
                    </div>

                    <ng-container *ngIf="showFormInvalidMessage">
                        <div class="col-12 col-sm-6 text-right p-r-16 cl-error-color">
                            {{ ('Customer_Form.Required_Fields_Title' | trans) }}<br>
                            {{ ('Customer_Form.Required_Fields' | trans) }}
                        </div>
                    </ng-container>

                    <div class="col-12 text-right" [class.col-sm-8]="!showFormInvalidMessage"
                         [class.col-sm-2]="showFormInvalidMessage">
                        <button class="btn btn-cta"
                                (click)="saveCustomer(customer)">{{ ('General.Save' | trans) }}
                        </button>
                    </div>
                </ng-container>
            </div>
        </div>

        <div class="page-section">
            <div class="form-group">

            </div>

        </div>

        <div class="page-section">
            <div class="row gutter-16">

                <div class="col-12 col-sm-6">
                    <div class="card">
                        <h3>{{ ('Customer_Form.Tags' | trans) }}</h3>
                        <ng-container *ngIf="customerTags$ | async as customerTags">
                            <app-form-checkbox formId="customer"
                                               [(value)]="customerTagIds"
                                               [options]="customerTags"
                                               optionLabel="name"
                                               optionValue="id"
                                               [columns]="2">
                            </app-form-checkbox>
                        </ng-container>
                    </div>
                </div>

                <div class="col-12 col-sm-6">
                    <div class="card">
                        <app-activity-log [customerIds]="[customer.id]"></app-activity-log>
                    </div>
                </div>
            </div>
            <div class="row gutter-16">
                <div class="col-12 col-sm-6">
                    <div class="card">
                        <h3>{{ ('Customer_Form.Custom_Fields' | trans) }}</h3>
                        <ng-container *ngFor="let customFieldAnswer of customer.customFieldAnswers">
                            <ng-container *ngFor="let customField of customFields">
                                <ng-container *ngIf="customFieldAnswer.customFieldId === customField.id">
                                    <ng-container
                                        *ngIf="customField.type === 'TEXT'">
                                        <div class="col-12 col-sm-6">
                                            <app-form-input formId="customer"
                                                            [label]="customField.label"
                                                            [type]="customField.type"
                                                            [(value)]="customFieldAnswer.value">
                                            </app-form-input>
                                        </div>
                                    </ng-container>
                                    <ng-container
                                        *ngIf="customField.type === 'CHECKBOX'">
                                        <div class="col-12 col-sm-6">
                                            <div class="form-check">
                                                <label>
                                                    <input type="checkbox"
                                                           (change)="customFieldAnswer.value = $event.target.checked"
                                                           [checked]="customFieldAnswer.value ? true : null">
                                                    <span class="label">{{ customField.label }}</span>
                                                </label>
                                            </div>
                                        </div>
                                    </ng-container>
                                    <ng-container
                                        *ngIf="customField.type === 'MULTISELECT'">
                                        <div class="col-12 col-sm-6">
                                            <app-form-checkbox formId="customer"
                                                               [(value)]="customFieldAnswer.customFieldOptionIds"
                                                               [label]="customField.label"
                                                               [options]="customField.customFieldOptions"
                                                               optionLabel="label"
                                                               optionValue="id">
                                            </app-form-checkbox>
                                        </div>
                                    </ng-container>
                                    <ng-container *ngIf="customField.type === 'RADIO'">
                                        <div class="col-12 col-sm-6">
                                            <app-form-radio formId="customer"
                                                            [(value)]="customFieldAnswer.value"
                                                            [label]="customField.label"
                                                            [options]="customField.customFieldOptions"
                                                            optionLabel="label"
                                                            optionValue="id">
                                            </app-form-radio>
                                        </div>
                                    </ng-container>
                                    <ng-container *ngIf="customField.type === 'SELECT'">
                                        <div class="col-12 col-sm-6">
                                            <app-form-select formId="customer"
                                                             [(value)]="customFieldAnswer.value"
                                                             [label]="customField.label"
                                                             [options]="customField.customFieldOptions"
                                                             optionLabel="label"
                                                             optionValue="id">
                                            </app-form-select>
                                        </div>
                                    </ng-container>
                                </ng-container>
                            </ng-container>
                        </ng-container>
                    </div>
                </div>

                <div class="col-12 col-sm-6">
                    <div class="card">
                        <h3>{{ ('Customer_Form.Consents' | trans) }}</h3>
                        <ng-container *ngFor="let consentGrant of customer.consentGrants">
                            <ng-container *ngIf="consentGrant.consent?.isRequired">
                                <div class="row consent">
                                    <div class="col-10">
                                        <span class="consent-name">{{ consentGrant.name }}</span>
                                        <ng-container *ngIf="consentGrant.resource?.link">
                                            <a class="consent-resource-link"
                                               [href]="consentGrant.resource.link"
                                               target="_blank">
                                                <span class="cm-icon cm-icon-pop-out small" aria-hidden="true"></span>
                                            </a>
                                        </ng-container>
                                    </div>
                                </div>
                                <ng-container *ngIf="consentGrant.eventName || consentGrant.subscriptionTypeName">
                                    <div class="consent-info" [innerHTML]="'Customer_Form.Consents.Details' | trans | tags:[
                                    {
                                        key  : 'date',
                                        value: consentGrant.createdAt | date: 'EE d MMM y H:mm'
                                    },
                                    {
                                        key  : 'eventName',
                                        value: consentGrant.eventName ?? consentGrant.subscriptionTypeName
                                    }
                                ]">
                                    </div>
                                </ng-container>
                                <ng-container *ngIf="!consentGrant.eventName && !consentGrant.subscriptionTypeName">
                                    <div class="consent-info">
                                        {{ ('Customer_Form.Consents.Accepted_On' | trans) }} {{ consentGrant.createdAt | date: 'EE d MMM y H:mm' }}
                                        {{ ('Customer_Form.Consents.During_Order' | trans) }}
                                    </div>
                                </ng-container>
                            </ng-container>
                        </ng-container>
                    </div>

                    <div class="card">
                        <h3>{{ ('Customer_Form.OptIns' | trans) }}</h3>
                        <ng-container *ngFor="let consentGrant of customer.consentGrants">
                            <ng-container *ngIf="!consentGrant.consent?.isRequired">
                                <div class="row consent">
                                    <div class="col-10">
                                        <span class="consent-name">{{ consentGrant.name }}</span>
                                        <ng-container *ngIf="consentGrant.resource?.link">
                                            <a class="consent-resource-link"
                                               [href]="consentGrant.resource.link"
                                               target="_blank">
                                                <span class="cm-icon cm-icon-pop-out small" aria-hidden="true"></span>
                                            </a>
                                        </ng-container>
                                    </div>
                                </div>
                                <ng-container *ngIf="consentGrant.eventName || consentGrant.subscriptionTypeName">
                                    <div class="consent-info" [innerHTML]="'Customer_Form.Consents.Details' | trans | tags:[
                                    {
                                        key  : 'date',
                                        value: consentGrant.createdAt | date: 'EE d MMM y H:mm'
                                    },
                                    {
                                        key  : 'eventName',
                                        value: consentGrant.eventName ?? consentGrant.subscriptionTypeName
                                    }
                                ]">
                                    </div>
                                </ng-container>
                                <ng-container *ngIf="!consentGrant.eventName && !consentGrant.subscriptionTypeName">
                                    <div class="consent-info">
                                        {{ ('Customer_Form.Consents.Accepted_On' | trans) }} {{ consentGrant.createdAt | date: 'EE d MMM y H:mm' }} {{ ('Customer_Form.Consents.During_Order' | trans) }}
                                    </div>
                                </ng-container>
                            </ng-container>
                        </ng-container>
                    </div>
                </div>
            </div>
        </div>

    </div>

</ng-container>
