import {ModelBase} from '../abstract/model-base';

export class OrderDetailsInvoice extends ModelBase {
    public id;
    public invoiceId;
    public vatNumber;
    public reference;
    public companyName;
    public firstName;
    public insertion;
    public lastName;
    public street;
    public houseNumber;
    public postalCode;
    public city;
    public province;
    public country;
    public downloadUrl;

    constructor() {
        super();
        super.setRoutes({
            post: 'v1.0/order/:orderId/invoice',
            delete: 'v1.0/order/:orderId/invoice/:orderInvoiceId'
        });
    }

    mapModel(data: any): OrderDetailsInvoice {
        const model = new OrderDetailsInvoice();

        model.id = data.id;
        model.invoiceId = data.invoiceId;
        model.vatNumber = data.vatNumber;
        model.reference = data.reference;
        model.companyName = data.companyName;
        model.firstName = data.firstName;
        model.insertion = data.insertion;
        model.lastName = data.lastName;
        model.street = data.street;
        model.houseNumber = data.houseNumber;
        model.postalCode = data.postalCode;
        model.city = data.city;
        model.province = data.province;
        model.country = data.country;
        model.downloadUrl = data.downloadUrl;

        return model;
    }
}
