import {Injectable} from '@angular/core';
import {HttpClient, HttpParams, HttpResponse} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Event, mapEvent} from '../../models/event.class';
import {environment} from '../../../../environments/environment';
import {map} from 'rxjs/operators';
import {EventTicket, mapEventTicket} from '../../models/event-ticket.class';
import {EventStatistics, mapEventStatistics} from '../../models/event-statistics.class';
import {EventTicketStatistics} from '../../models/event-ticket-statistics.class';

@Injectable()
export class EventService {
    private baseUrl = environment.CM_API_URL + '/seatedapi';
    private config = {
        withCredentials: true,
        params: {}
    };

    constructor(private http: HttpClient) {
    }

    getEvents(params?: HttpParams): Observable<HttpResponse<Event[]>> {
        const config = Object.assign({}, this.config);
        config.params = params;

        return this.http.get<Event[]>(this.baseUrl + `/v1.0/event`, {...config, observe: 'response'}).pipe(
            map(response => {
                const body = response.body.map(event => {
                    return mapEvent(event);
                });

                return response.clone({body});
            })
        );
    }

    getEvent(eventId: string, params?: HttpParams): Observable<Event> {
        const config = Object.assign({}, this.config);
        config.params = params;

        return this.http.get<Event>(this.baseUrl + `/v1.0/event/${eventId}`, config).pipe(
            map((item: Event) => {
                return mapEvent(item);
            })
        );
    }

    postEvent(event: Event, params?: HttpParams): Observable<Event> {
        const config = Object.assign({}, this.config);
        config.params = params;

        return this.http.post<Event>(this.baseUrl + `/v1.0/event`, event, config).pipe(
            map((item: Event) => {
                return mapEvent(item);
            })
        );
    }

    patchEvent(event: Partial<Event>, params?: HttpParams): Observable<Event> {
        const config = Object.assign({}, this.config);
        config.params = params;

        return this.http.patch<Event>(this.baseUrl + `/v1.0/event/${event.id}`, event, config).pipe(
            map((item: Event) => {
                return mapEvent(item);
            })
        );
    }

    deleteEvent(event: Event): Observable<Event> {
        return this.http.delete<Event>(this.baseUrl + `/v1.0/event/${event.id}`, this.config);
    }

    getEventStatistics(eventId: string): Observable<EventStatistics> {
        return this.http.get<EventStatistics>(this.baseUrl + `/v1.0/event/${eventId}/statistics`, this.config)
            .pipe(
                map((item: EventStatistics) => {
                    return mapEventStatistics(item);
                })
            );
    }

    getEventTickets(params: HttpParams): Observable<HttpResponse<EventTicket[]>> {
        const config = Object.assign({}, this.config);
        config.params = params;

        return this.http.get<EventTicket[]>(this.baseUrl + `/v1.0/event/ticket`, {
            ...config,
            observe: 'response'
        }).pipe(
            map(response => {
                const body = response.body.map(eventTicket => mapEventTicket(eventTicket));

                return response.clone({body: body});
            })
        );
    }

    getEventTicketsSimple(params: HttpParams): Observable<HttpResponse<EventTicket[]>> {
        const config = Object.assign({}, this.config);
        config.params = params;

        return this.http.get<EventTicket[]>(this.baseUrl + `/v1.0/event/ticket/simple`, {
            ...config,
            observe: 'response'
        }).pipe(
            map(response => {
                const body = response.body.map(eventTicket => mapEventTicket(eventTicket));

                return response.clone({body: body});
            })
        );
    }

    getEventTicketStatistics(params: HttpParams): Observable<EventTicketStatistics> {
        const config = Object.assign({}, this.config);
        config.params = params;

        return this.http.get<EventTicketStatistics>(this.baseUrl + `/v1.0/event/ticket/statistics`, {...config});
    }

    updateEventTicket(eventTicketId: string, body: any, params?: HttpParams): Observable<EventTicket> {
        const config = Object.assign({}, this.config);
        config.params = params;

        return this.http.patch<EventTicket>(this.baseUrl + `/v1.0/event/ticket/${eventTicketId}`, body, config);
    }

    updateEventTickets(body: any, params?: HttpParams): Observable<EventTicket[]> {
        const config = Object.assign({}, this.config);
        config.params = params;

        return this.http.patch<EventTicket[]>(this.baseUrl + `/v1.0/event/ticket`, body, config);
    }

    renewEventTickets(body: any, params?: HttpParams): Observable<EventTicket[]> {
        const config = Object.assign({}, this.config);
        config.params = params;

        return this.http.patch<EventTicket[]>(this.baseUrl + `/v1.0/event/tickets/renew-barcode`, body, config);
    }

    public getCustomerEventTickets(customerId: string, params: HttpParams): Observable<HttpResponse<EventTicket[]>> {
        return this.http.get<EventTicket[]>(this.baseUrl + `/v1.0/customer/${customerId}/ticket`, {
            withCredentials: true,
            params: params,
            observe: 'response'
        }).pipe(
            map(response => {
                const body = response.body.map(eventTicket => mapEventTicket(eventTicket));
                return response.clone({body: body});
            })
        );
    }
}
