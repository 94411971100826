import {CustomerNameHelper} from '../../core/helpers/customer-name-helper';

export class CustomerListItem {
    isSelected: boolean;

    constructor(public id: string = null,
                public externalReference1: string = null,
                public type: string = null,
                public companyName: string = null,
                public firstName: string = null,
                public insertion: string = null,
                public lastName: string = null,
                public email: string = null,
                public customerTags: string[] = [],
                public isDeleted: boolean,
                public blacklist?: {
                    id: string,
                    source: string,
                    startAt: string,
                    endAt: string
                }) {
    }

    getName(): string {
        return CustomerNameHelper.getName(this);
    }
}

export function mapCustomerListItem(item: any): CustomerListItem {
    return new CustomerListItem(
        item.id,
        item.externalReference1,
        item.type,
        item.companyName,
        item.firstName,
        item.insertion,
        item.lastName,
        item.email,
        item.customerTags,
        item.isArchived,
        item.blacklist
    );
}
