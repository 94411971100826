export enum OrganisationSettingIds {
    ORGANISATION_NAME = 'ORGANISATION_NAME',
    DATA_ENABLED = 'DATA_ENABLED',
    DATA_SPORTS_ALLIANCE_ENABLED = 'DATA_SPORTS_ALLIANCE_ENABLED',
    FANALIST_ENABLED = 'FANALIST_ENABLED',
    C3V_ENABLED = 'C3V_ENABLED',
    C3V_CLUB_ID = 'C3V_CLUB_ID',
    C3V_SECRET_KEY = 'C3V_SECRET_KEY',
    AFAS_ENABLED = 'AFAS_ENABLED',
    AFAS_API_ENDPOINT = 'AFAS_API_ENDPOINT',
    RELATION_ID_ON_CREATE = 'RELATION_ID_ON_CREATE',
    GEOCODE_MODE = 'GEOCODE_MODE',
    CUSTOMER_DEFAULT_LANGUAGE = 'CUSTOMER_DEFAULT_LANGUAGE',
    EXTERNAL_TICKETS_ENABLED = 'EXTERNAL_TICKETS_ENABLED',
    ORGANISATION_TIMEZONE = 'ORGANISATION_TIMEZONE',
    ORGANISATION_VAT = 'ORGANISATION_VAT',
    ORGANISATION_CURRENCY = 'ORGANISATION_CURRENCY',
    IS_USING_WHITE_LABEL = 'IS_USING_WHITE_LABEL',
    IS_RESELL_ENABLED = 'IS_RESELL_ENABLED',
    IDIN_MERCHANT_TOKEN = 'IDIN_MERCHANT_TOKEN',
    USES_BOOKING_FEE_FUNCTIONALITY = 'USES_BOOKING_FEE_FUNCTIONALITY',
    IS_FINANCE_ORGANISATION = 'IS_FINANCE_ORGANISATION'
}

export enum PersonalisationSettingId {
    TICKET_BUYER = 'PERSONALISATION_TICKET_BUYER',
    TICKET_HOLDER = 'PERSONALISATION_TICKET_HOLDER',
    TICKET_HOLDER_CONFIRMATION = 'PERSONALISATION_TICKET_HOLDER_CONFIRMATION',
    THEME = 'PERSONALISATION_THEME',
    BACKGROUND_IMAGE = 'PERSONALISATION_BACKGROUND_IMAGE',
    ACCENT_COLOR = 'PERSONALISATION_ACCENT_COLOR',
    DOWNLOAD_BUTTON_ENABLED = 'PERSONALISATION_DOWNLOAD_BUTTON_ENABLED'
}
