<cm-dialog data-max-width="600px" [id]="id">
    <h3 slot="header" class="dialog-title">
        {{ ('Venue_Section_Seat_Setting.Edit_Capacity' | trans) }}
    </h3>

    <div slot="body" class="form-group">
        <h4 [cm-tooltip]="('Venue_Section_Seat_Setting.Exception_Help' | trans)">
            {{ ('Venue_Section_Seat_Setting.Exception' | trans) }} <span class="cm-icon cm-icon-help-open"
                                                                         aria-hidden="true"></span>
        </h4>

        <ng-container *ngFor="let venueSectionSeat of venueSectionSeats">
            <table class="table form-group w-100 mt-32">
                <thead>
                <tr>
                    <th>{{ ('Venue_Section_Seat_Setting.Original_Capacity' | trans) }}</th>
                    <th>{{ ('Venue_Section_Seat_Setting.New_Capacity' | trans) }}</th>
                    <th>{{ ('Venue_Section_Seat_Setting.Change' | trans) }}</th>
                    <th></th>
                </tr>
                </thead>
                <tbody>
                <ng-container *ngFor="let venueSectionSeatSetting of venueSectionSeat.venueSectionSeatSettings">
                    <ng-container
                        *ngIf="venueSectionSeatSetting.eventId && venueSectionSeatSetting.eventId === eventId">
                        <tr>
                            <td>{{ venueSectionSeat.capacity }}</td>
                            <td>
                                <app-form-input type="number"
                                                [(value)]="venueSectionSeatSetting.capacity">
                                </app-form-input>
                            </td>
                            <td>
                                <app-form-input type="number"
                                                [value]="venueSectionSeatSetting.capacity - venueSectionSeat.capacity"
                                                (valueChange)="venueSectionSeatSetting.capacity = venueSectionSeat.capacity + +$event">
                                </app-form-input>
                            </td>
                            <td>
                                <cm-button data-button-type="icon" data-icon="delete" data-button-style="secondary"
                                           (click)="deleteVenueSectionSeatSetting(venueSectionSeat, venueSectionSeatSetting)">
                                </cm-button>
                            </td>
                        </tr>
                    </ng-container>
                </ng-container>
                </tbody>
            </table>
            <cm-button data-button-type="icon-text" data-icon="add" [attr.data-label]="'General.Add' | trans"
                    data-button-style="secondary"
                    (click)="addVenueSectionSeatSetting(venueSectionSeat, true)"
                    [disabled]="isDisabled(venueSectionSeat, true)">
            </cm-button>
        </ng-container>
    </div>

    <div slot="footer" class="m-t-32">
        <cm-button data-button-style="secondary" [attr.data-label]="'Dialog.Cancel' | trans"
                   (click)="cancel()"></cm-button>
        <cm-button [attr.data-label]="'Dialog.Ok' | trans" (click)="confirm()"></cm-button>
    </div>

</cm-dialog>
