<router-outlet></router-outlet>
<app-menu></app-menu>

<ng-container *ngIf="maintenanceMessage">
    <cm-banner data-type="warning">
        <span slot="icon" class="cm-icon cm-icon-warning-open" aria-hidden="true"></span>
        <span slot="content">
            {{ ('Maintenance_Mode.Banner' | trans) | tags: maintenanceMessageTags }}
        </span>
        <span slot="buttons">
            <ng-container *ngIf="maintenanceMessage.statusPageUrl">
                <cm-button
                    data-icon="pop-out"
                    [attr.data-label]="'Maintenance_Mode.Banner.Read_More_Button.Label' | trans"
                    data-button-style="primary"
                    data-button-type="icon-text"
                    data-button-size="medium"
                    data-custom-classes="bg-background-color"
                    (click)="onMaintenanceModeReadMoreClick()">
                </cm-button>
            </ng-container>

            <cm-button
                data-icon="close"
                data-button-style="secondary"
                data-button-type="icon"
                data-button-size="medium"
                (click)="onCloseMaintenanceBannerClick()">
            </cm-button>
        </span>
    </cm-banner>
</ng-container>

<div class="technical-link" (click)="copyToClipboard(technicalLinkId)">{{ technicalLinkId }}</div>

<app-download-manager></app-download-manager>
<app-action-footer></app-action-footer>
<app-progress-notification></app-progress-notification>
