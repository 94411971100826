@if (customerOrders$ | async; as customerOrders) {
    @if (customerOrders.length) {
        <cm-table #table data-not-resizable>
            <cm-table-head>
                <cm-table-row>
                    <cm-table-header>{{ ('Relations.Order_Number' | trans) }}</cm-table-header>
                    <cm-table-header>{{ ('Relations.Amount' | trans) }}</cm-table-header>
                    <cm-table-header>{{ ('Relations.Venue_Sections' | trans) }}</cm-table-header>
                    <cm-table-header>{{ ('Relations.Order_Expires_At' | trans) }}</cm-table-header>
                    <cm-table-header>{{ ('Relations.Order_Paid_At' | trans) }}</cm-table-header>
                    <cm-table-header>{{ ('Relations.Order_Total_Price' | trans) }}</cm-table-header>
                </cm-table-row>
            </cm-table-head>
            <cm-table-body>
                @for (customerOrder of customerOrders; track customerOrder.orderId) {
                    <cm-table-row (click)="openOrderDetail(customerOrder)">
                        <cm-table-data data-icon>
                            <span [attr.class]="'cm-icon small ' + getStatusIconClasses(customerOrder.status)"></span>
                            <span>{{ customerOrder.batchId }}</span>
                        </cm-table-data>
                        <cm-table-data>{{ customerOrder.amount }}</cm-table-data>
                        <cm-table-data>
                            {{ customerOrder.venueSections[0] }}
                            @if (customerOrder.venueSections.length > 1) {
                                <span [cm-tooltip]="customerOrder.venueSections.join('<br>')" class="cl-selected-color">
                                +{{ customerOrder.venueSections.length - 1 }}
                            </span>
                            }
                        </cm-table-data>
                        <cm-table-data>{{ customerOrder.expireAt ? (customerOrder.expireAt | date) : '-' }}</cm-table-data>
                        <cm-table-data>{{ customerOrder.payedAt | date }}</cm-table-data>
                        <cm-table-data>{{ customerOrder.totalPrice | currency }}</cm-table-data>
                    </cm-table-row>
                }
            </cm-table-body>
        </cm-table>
        <cm-pagination
            [attr.data-skip]="paginationSkip"
            [attr.data-take]="paginationTake"
            [attr.data-total]="paginationTotal"
            [attr.data-page-size-options]="paginationOptions"
            data-show="3"
            data-show-totals
            data-show-page-size
            (change)="onPaginationChange($event)">
        </cm-pagination>
    } @else if (ordersTableLoading) {
        <cm-indicator></cm-indicator>
    } @else {
        <div class="page-section">
            <app-empty-state>
                <div class="empty-state">
                    <div class="empty-state-header">{{ ('General.Empty_State.Title' | trans) }}</div>
                    <div
                        class="empty-state-description">{{ ('Customer_Order_List.Empty_State.Refresh_Body' | trans) }}
                    </div>
                    <div class="empty-state-actions">
                        <cm-button
                            [attr.data-label]="('Customer_Order_List.Empty_State.Refresh_Button' | trans)"
                            data-button-style="secondary"
                            data-button-type="text"
                            data-button-size="medium"
                            (click)="ordersTableUpdate()">
                        </cm-button>
                    </div>
                </div>
            </app-empty-state>
        </div>
    }
}
