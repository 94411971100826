import {Injectable, Injector} from '@angular/core';
import {filter, first, map} from 'rxjs/operators';
import {SeatedApiService} from '../seated-api/seated-api.service';
import { HttpParams } from '@angular/common/http';
import {BehaviorSubject, Subscription} from 'rxjs';
import {State} from './state.class';
import {ModelBase} from '../../../models/abstract/model-base';

@Injectable({
    providedIn: 'root'
})
export class StateManagementService {
    private seatedApiService: SeatedApiService;
    private state: State = new State();
    private store = new BehaviorSubject(this.state);
    private state$ = this.store.asObservable();
    private loadingSubject = new BehaviorSubject(false);

    public loading$ = this.loadingSubject.asObservable();
    public routes;
    public modelClass: ModelBase;
    public httpParams: HttpParams = new HttpParams();
    public list$ = this.state$.pipe(map(state => Object.assign([], state.list)));
    public itemId$ = this.state$.pipe(map(state => Object.assign(null, state.itemId)));
    public item$ = this.state$.pipe(filter(state => !!state.item), map(state => this.modelClass.mapModel(state.item)));
    public originalItem$ = this.state$.pipe(map(state => Object.assign(null, state.originalItem)));

    constructor(injector: Injector) {
        this.seatedApiService = injector.get(SeatedApiService);
    }

    initialize(httpParams: HttpParams = null, initializeList = true): StateManagementService {
        if (httpParams) {
            this.httpParams = httpParams;
        }

        if (initializeList) {
            this.syncList();
        }

        return this;
    }

    syncItem(itemId: string, refresh = false): void {
        this.state.itemId = itemId ?? this.state.itemId;

        const stateItemId = this.state.itemId;
        const item = this.state.list.find(listItem => listItem.id === stateItemId);

        if (item && !refresh) {
            this.setItem(item);
            return;
        }

        this.loadingSubject.next(true);
        this.seatedApiService
            .get<ModelBase>(this.modelClass, {id: stateItemId}, this.httpParams)
            .pipe(first()).subscribe(itemObject => {
                this.loadingSubject.next(false);
                this.setItem(itemObject);
            }
        );
    }

    syncList() {
        this.loadingSubject.next(true);

        const httpParams = this.httpParams.set('take', -1);
        this.seatedApiService.getMany<ModelBase>(this.modelClass, httpParams).pipe(first()).subscribe(list => {
            this.loadingSubject.next(false);
            this.state.list = list;
            this.store.next(this.state);
        });
    }

    private setItem(item: ModelBase) {
        const itemIndex = this.state.list.findIndex(listItem => {
            return listItem.id === this.state.itemId;
        });

        if (itemIndex >= 0) {
            this.state.list[itemIndex] = item;
        } else {
            this.state.list.push(item);
        }

        this.state.itemId = item.id;
        this.state.item = item;
        this.state.originalItem = item;

        this.store.next(this.state);
    }

    private setList(list: ModelBase[]) {
        this.state.list = list;

        this.store.next(this.state);
    }

    clearItem(): void {
        this.state.itemId = null;
        this.state.item = null;
        this.store.next(this.state);
    }

    addItem(newItem: ModelBase, onItemCreated: (item) => any): Subscription {
        if (this.state.item?.id) {
            delete this.state.item.id;
        }

        this.state.item = newItem;
        return this.seatedApiService.post<ModelBase>(this.modelClass, [], newItem, this.httpParams).subscribe(item => {
            this.setItem(item);
            onItemCreated(item);
        });
    }

    overwriteItem(newItem) {
        this.state.item = newItem;

        const originalIndex = this.state.list.findIndex((listItem => listItem.id === newItem.id));
        this.state.list[originalIndex] = newItem;

        this.store.next(this.state);
    }

    updateItem(newItemState = null, idValue: string, onItemUpdated: (item) => any = () => {}): Subscription {
        if (!newItemState) {
            newItemState = this.state.item;
        }

        return this.seatedApiService.patch<ModelBase>(this.modelClass, {id: idValue}, newItemState, this.httpParams).subscribe(item => {
            this.setItem(item);
            onItemUpdated(item);
        });
    }

    saveList(newListState, routeParams?: any, onSuccess?: () => any, onFailure?: () => any) {
        return this.seatedApiService.postBulk<ModelBase[]>(this.modelClass, routeParams, newListState, this.httpParams).subscribe({
            next: () => {
                onSuccess();
            },
            error: () => {
                onFailure();
            }
        });
    }

    deleteItem() {
        return this.seatedApiService.delete<ModelBase>(this.modelClass, {
            id: this.state.item.id
        }).subscribe(() => {
            const itemIndex = this.state.list.findIndex(item => item.id === this.state.item.id);

            if (itemIndex >= 0) {
                this.state.list.splice(itemIndex, 1);
            }

            this.state.itemId = null;
            this.state.item = null;
            this.state.originalItem = null;

            this.store.next(this.state);
        });
    }
}
