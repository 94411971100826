<ng-container *ngFor="let payment of orderDetailsPayments">
    <div class="row payment">
        <div class="col-6 d-flex align-items-center">
            <ng-container *ngTemplateOutlet="paymentMethod; context: { $implicit: payment }"></ng-container>
        </div>
        <ng-container *ngTemplateOutlet="paymentStatus; context: { $implicit: payment }"></ng-container>
    </div>

    <ng-container *ngFor="let refund of payment.refunds">
        <div class="row payment refund">
            <div class="col-8">
                <span class="payment-method-name fs-small">
                    {{ ('Order_Payment_Table.Refund_Label.' + refund.status | trans) | tags:[
                    { key: 'amount', value: (refund.amount | currency:payment.currency) }
                ]}}
                </span>
            </div>
            <div class="col-4 text-right">
                <div class="fs-small" [cm-tooltip]="refund.refundedAt | date:'EE d MMM y H:mm'">
                    {{ refund.refundedAt | date:'shortDate' }}
                </div>
            </div>
        </div>
    </ng-container>
</ng-container>

<ng-template #paymentMethod let-payment>
    <span *ngIf="payment.method === 'IDEAL'"
          [cm-tooltip]="payment.method"
          class="cm-payment cm-payment-ideal vertical-middle"
          aria-hidden="true">
    </span>
    <span *ngIf="payment.method === 'BANCONTACT'"
          [cm-tooltip]="payment.method"
          class="cm-payment cm-payment-bancontact vertical-middle"
          aria-hidden="true">
    </span>
    <span *ngIf="payment.method === 'SOFORT_BANKING'"
          [cm-tooltip]="payment.method"
          class="cm-payment cm-payment-sofort vertical-middle"
          aria-hidden="true">
    </span>
    <span *ngIf="payment.method === 'MASTER_CARD'"
          [cm-tooltip]="payment.method"
          class="cm-payment cm-payment-mastercard vertical-middle"
          aria-hidden="true">
    </span>
    <span *ngIf="payment.method === 'VISA_CARD'"
          [cm-tooltip]="payment.method"
          class="cm-payment cm-payment-visa vertical-middle"
          aria-hidden="true">
    </span>
    <span *ngIf="payment.method === 'BANK_TRANSFER'"
          [cm-tooltip]="payment.method"
          class="cm-payment cm-payment-bank-transfer vertical-middle"
          aria-hidden="true">
    </span>
    <span *ngIf="payment.method === 'IN_THREE'"
          [cm-tooltip]="payment.method"
          class="cm-payment cm-payment-in3 vertical-middle"
          aria-hidden="true">
    </span>
    <span *ngIf="payment.method === 'CASH'"
          [cm-tooltip]="payment.method"
          class="cm-icon cm-icon-money vertical-middle"
          aria-hidden="true">
    </span>

    <span class="payment-method-name">{{ payment.method }}<br></span>
</ng-template>

<ng-template #paymentStatus let-payment>
    <div class="col-6 text-right"
         [class.d-flex]="isRefreshableStatus(payment.status)"
         [class.align-items-center]="isRefreshableStatus(payment.status)"
         [class.justify-content-end]="isRefreshableStatus(payment.status)"
    >
        <div>
            <span
                [class.cl-success-color]="payment.status === 'SUCCESS'"
                [class.cl-error-color]="['CANCELLED', 'EXPIRED', 'FAILURE'].includes(payment.status)"
                [class.cl-warning-color]="payment.status === 'OPEN'">
                {{ payment.status }}
            </span>
        </div>
        @if (isRefreshableStatus(payment.status)) {
            <button class="btn btn-secondary-icon vertical-align-middle"
                    (click)="updatePaymentStatus(payment)">
                <span class="cm-icon cm-icon-refresh" aria-hidden="true"></span>
            </button>
        } @else {
            <div class="fs-small" [cm-tooltip]="payment.payedAt | date:'EE d MMM y H:mm'">
                {{ payment.balance | currency:payment.currency }} | {{ payment.payedAt | date:'shortDate' }}
            </div>
        }
    </div>
</ng-template>
