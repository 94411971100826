import {Injectable} from '@angular/core';
import {environment} from '../../../../environments/environment';
import {HttpClient, HttpParams, HttpResponse} from '@angular/common/http';
import {Observable} from 'rxjs';
import {mapOrder, Order} from '../../models/order.class';
import {mapOrderItem, OrderItem} from '../../models/order-item.class';
import {map} from 'rxjs/operators';
import {HttpParamsHelper} from '../../helpers/http-params/http-params-helper';
import {OrderDetails} from '../../models/order-details/order-details.class';
import {mapOrderItemListGroup, OrderItemListGroup} from '../../models/order-item-details/order-item-list-group.class';

@Injectable()
export class OrderService {
    private config = {
        withCredentials: true,
        params: {}
    };

    private baseUrl = environment.CM_API_URL + '/seatedapi';

    constructor(private http: HttpClient) {
    }

    getOrder(orderId: string, params?: HttpParams): Observable<Order> {
        const config = Object.assign({}, this.config);
        config.params = params;

        return this.http.get<Order>(this.baseUrl + `/v1.0/order/${orderId}`, config)
            .pipe(
                map((item: Order) => {
                    return mapOrder(item);
                })
            );
    }

    getOrders(params: HttpParams): Observable<HttpResponse<Order[]>> {
        const config = Object.assign({}, this.config);
        config.params = params;

        return this.http.get<Order[]>(this.baseUrl + `/v1.0/order`, {
            ...config,
            observe: 'response'
        }).pipe(
            map((response: HttpResponse<Order[]>) => {
                const body = response.body.map(order => {
                    return mapOrder(order);
                });

                return response.clone({body});
            })
        );
    }

    getMagicLink(orderId: string): Observable<any> {
        const config = Object.assign({}, this.config);

        return this.http.get<Order>(this.baseUrl + `/v1.0/order/${orderId}/magic-link`, config);
    }

    patchOrderItem(orderId: string, orderItemId: string, body: any, params?: HttpParams): Observable<OrderItem> {
        const config = Object.assign({params}, this.config);
        return this.http.patch<OrderItem>(this.baseUrl + `/v1.0/order/${orderId}/item/${orderItemId}`, body, config)
            .pipe(
                map((item: OrderItem) => {
                    return mapOrderItem(item);
                })
            );
    }

    updateOrder(orderId: string, body: any, params?: HttpParams): Observable<Order> {
        const config = Object.assign({}, this.config);
        config.params = params;

        return this.http.put<Order>(this.baseUrl + `/v1.0/order/${orderId}`, body, config)
            .pipe(
                map((item: Order) => {
                    return mapOrder(item);
                })
            );
    }

    postOrder(body = {}, params?: HttpParams): Observable<Order> {
        this.config.params = params;

        return this.http.post<Order>(this.baseUrl + `/v1.0/order`, body, this.config)
            .pipe(
                map((item: Order) => {
                    return mapOrder(item);
                })
            );
    }

    deleteOrder(orderId: string) {
        return this.http.delete(this.baseUrl + `/v1.0/order/${orderId}`, this.config);
    }

    postOrderItem(orderId: string, body: any, params?: HttpParams): Observable<{ id: string, orderId: string }> {
        const config = Object.assign({}, this.config);
        config.params = params;
        return this.http.post<OrderItem>(this.baseUrl + `/v1.0/order/${orderId}/item`, body, config)
            .pipe(
                map((item: { id: string, orderId: string }) => {
                    return mapOrderItem(item);
                })
            );
    }

    postOrderItems(orderId: string, body: any, params?: HttpParams): Observable<{ id: string, orderId: string }[]> {
        const config = Object.assign(this.config, {params});
        if (body[0]?.ticket?.eventId) {
            config.params = HttpParamsHelper.setHttpParam(params, 'scope[eventId]', body[0]?.ticket?.eventId);
        }
        return this.http.post<{
            id: string,
            orderId: string
        }[]>(this.baseUrl + `/v1.0/order/${orderId}/item?venueSectionSeat[sectionName]=true`, body, config);
    }

    updateOrderItem(orderId: string, orderItemId: string, body: any, params?: HttpParams): Observable<OrderItem> {
        const config = Object.assign(this.config, {params});
        return this.http.put<OrderItem>(this.baseUrl + `/v1.0/order/${orderId}/item/${orderItemId}`, body, config)
            .pipe(
                map((item: OrderItem) => {
                    return mapOrderItem(item);
                })
            );
    }

    updateOrderItems(orderId: string, body: any, params?: HttpParams): Observable<OrderItem[]> {
        const config = Object.assign(this.config, {params});
        return this.http.put<OrderItem[]>(this.baseUrl + `/v1.0/order/${orderId}/item`, body, config);
    }

    deleteOrderItems(orderId: string, orderItems: string[]) {
        return this.http.request('delete', this.baseUrl + `/v1.0/order/${orderId}/item`, {
            body: orderItems,
            ...this.config
        });
    }

    deleteOrderItem(orderId: string, orderItem: string) {
        return this.http.delete(this.baseUrl + `/v1.0/order/${orderId}/item/${orderItem}`, this.config);
    }

    getOrderItem(orderId: string, orderItemId: string, params?: HttpParams): Observable<OrderItem> {
        const config = Object.assign({}, this.config);
        config.params = params;

        return this.http.get<OrderItem>(this.baseUrl + `/v1.0/order/${orderId}/item/${orderItemId}`, config).pipe(
            map((item: OrderItem) => {
                return mapOrderItem(item);
            })
        );
    }

    getOrderItems(orderId: string, params?: HttpParams): Observable<OrderItem[]> {
        const config = Object.assign({}, this.config);
        config.params = params;

        return this.http.get<OrderItem[]>(this.baseUrl + `/v1.0/order/${orderId}/item`, config).pipe(
            map((items: OrderItem[]) => {
                return items.map(item => {
                    return mapOrderItem(item);
                });
            })
        );
    }

    postOrderDiscountCampaign(orderId: string, discountCampaignId, params?: HttpParams): Observable<Order> {
        const config = Object.assign({}, this.config);
        config.params = params;

        return this.http.post<Order>(this.baseUrl + `/v1.0/order/${orderId}/discountcampaign`, {discountCampaignId: discountCampaignId}, config).pipe(
            map((item: Order) => {
                return mapOrder(item);
            })
        );
    }

    postOrderDiscount(orderId: string, orderDiscountCode, params?: HttpParams): Observable<Order> {
        const config = Object.assign({}, this.config);
        config.params = params;

        return this.http.post<Order>(this.baseUrl + `/v1.0/order/${orderId}/discount`, {code: orderDiscountCode}, config).pipe(
            map((item: Order) => {
                return mapOrder(item);
            })
        );
    }

    deleteOrderDiscount(orderId: string, orderDiscountId: string, params?: HttpParams): Observable<Order> {
        const config = Object.assign(this.config, {params});
        config.params = params;

        return this.http.delete(this.baseUrl + `/v1.0/order/${orderId}/discount/${orderDiscountId}`, config).pipe(
            map((item: Order) => {
                return mapOrder(item);
            })
        );
    }

    public getOrderItemListGroups(orderId: string, params?: HttpParams): Observable<OrderItemListGroup[]> {
        const config = Object.assign({}, this.config);
        config.params = params;

        return this.http.get<OrderItemListGroup[]>(`${environment.CM_API_URL}/seatedapi/v2.0/dashboard/order/${orderId}/items`, config)
            .pipe(
                map((item: OrderItemListGroup[]) => {
                    return item.map((orderItemGroup: OrderItemListGroup) => mapOrderItemListGroup(orderItemGroup));
                })
            );
    }

    public bundleOrderItemDetailsPerVenueSection(orderItemListGroup: OrderItemListGroup[]): {
        [venueSectionName: string]: OrderItemListGroup[]
    } {
        const orderItemDetailsDictionary = {};

        orderItemListGroup.forEach((orderItemGroup: OrderItemListGroup) => {
            const orderItemDetails = orderItemGroup.orderItemDetails;
            const sections = orderItemDetails.map(orderItem => ({
                id: orderItem.sectionId,
                sectionName: orderItem.sectionName,
                ringName: orderItem.ringName
            }));

            const uniqueSections = Array.from(
                new Map(sections.map(section => [section.id, section])).values()
            ).sort((a, b) => {
                const ringCompare = (a.ringName || '').localeCompare(b.ringName || '');

                if (ringCompare === 0) {
                    return (a.sectionName || '').localeCompare(b.sectionName || '');
                }

                return ringCompare;
            });

            uniqueSections.forEach(section => {
                const orderItemDetailsPerVenueSectionGroup = new OrderItemListGroup();
                const itemsForSection = orderItemDetails.filter(orderItem => orderItem.sectionId === section.id);

                orderItemDetailsPerVenueSectionGroup._venueSection = section.sectionName;
                orderItemDetailsPerVenueSectionGroup._venueRing = section.ringName;
                orderItemDetailsPerVenueSectionGroup.name = orderItemGroup.name;
                orderItemDetailsPerVenueSectionGroup.date = orderItemGroup.date;
                orderItemDetailsPerVenueSectionGroup.orderItemDetails = itemsForSection;

                if (!orderItemDetailsDictionary[orderItemGroup.name]) {
                    orderItemDetailsDictionary[orderItemGroup.name] = [];
                }
                orderItemDetailsDictionary[orderItemGroup.name].push(orderItemDetailsPerVenueSectionGroup);
            });
        });

        return orderItemDetailsDictionary;
    }

    public getOrderDetails(orderId: string, params?: HttpParams): Observable<OrderDetails> {
        const config = Object.assign({}, this.config);
        config.params = params;

        return this.http.get<OrderDetails>(`${environment.CM_API_URL}/seatedapi/v2.0/dashboard/order/${orderId}/details`, config)
            .pipe(
                map((item: OrderDetails) => {
                    return (new OrderDetails()).mapModel(item);
                })
            );
    }

    public resetPersonalisationForOrderItem(orderItemHash: string): Observable<any> {
        return this.http.post(`${environment.CM_API_URL}/seatedapi/v2.0/personalisation/${orderItemHash}/reset`, {});
    }
}
