export class CustomerNameHelper {
    public static getName(customer: {
        companyName: string,
        firstName: string,
        insertion: string,
        lastName: string,
        email: string
    }): string {
        if (customer.companyName) {
            return customer.companyName;
        }

        if (customer.firstName || customer.insertion || customer.lastName) {
            return (
                (customer.firstName ? customer.firstName : '') + ' ' +
                (customer.insertion ? customer.insertion : '') + ' ' +
                (customer.lastName ? customer.lastName : '')
            ).replace(/\s\s+/g, ' ');
        }

        return customer.email ?? '';
    }
}
