import {ModelBase} from '../abstract/model-base';
import {OrderDetailsCustomerTag} from './order-details-customer-tag.class';
import {OrderDetailsCustomerBlackList} from './order-details-customer-blacklist.class';

export class OrderDetailsCustomer extends ModelBase {
    public id: string;
    public companyName: string;
    public firstName: string;
    public insertion: string;
    public lastName: string;
    public street: string;
    public houseNumber: string;
    public postalCode: string;
    public city: string;
    public province: string;
    public country: string;
    public email: string;
    public phoneNumber: string;
    public externalReference: string;
    public guestManagerOrganisationId: string;
    public isVerified: boolean;
    public blacklist: OrderDetailsCustomerBlackList;
    public customerTags: OrderDetailsCustomerTag[];
    public createdAt: string;
    public updatedAt: string;

    constructor() {
        super();
    }

    mapModel(data: any): OrderDetailsCustomer {
        const model = new OrderDetailsCustomer();

        model.id = data.id;
        model.companyName = data.companyName;
        model.firstName = data.firstName;
        model.insertion = data.insertion;
        model.lastName = data.lastName;
        model.street = data.street;
        model.houseNumber = data.houseNumber;
        model.postalCode = data.postalCode;
        model.city = data.city;
        model.province = data.province;
        model.country = data.country;
        model.email = data.email;
        model.phoneNumber = data.phoneNumber;
        model.externalReference = data.externalReference;
        model.guestManagerOrganisationId = data.guestManagerOrganisationId;
        model.isVerified = data.isVerified;
        model.blacklist = data.blacklist ? (new OrderDetailsCustomerBlackList()).mapModel(data.blacklist) : null;
        model.customerTags = data.customerTags ? data.customerTags.map((customerTag: any) => (new OrderDetailsCustomerTag()).mapModel(customerTag)) : [];
        model.createdAt = data.createdAt;
        model.updatedAt = data.updatedAt;

        return model;
    }

    getName(): string {
        if (this.firstName || this.insertion || this.lastName) {
            return (
                (this.firstName ? this.firstName : '') + ' ' +
                (this.insertion ? this.insertion : '') + ' ' +
                (this.lastName ? this.lastName : ''))
                .replace(/\s\s+/g, ' ');
        }

        if (this.companyName) {
            return this.companyName;
        }

        if (this.email) {
            return this.email;
        }

        return '';
    }
}
