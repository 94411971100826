<ng-container *ngIf="ticketTemplatesSizedPerTwelve">
    <div class="row justify-content-space-between p-r-24 m-l-8">
        <div class="form-group">
            <div class="form-row">
                <cm-input id="input-example"
                          type="text"
                          [attr.placeholder]="('Ticket_Template_List.Search_Template' | trans) | async"
                          (input)="searchTemplates($event.target.value)"
                          data-icons-left="search">
                </cm-input>
            </div>
        </div>

        <div class="form-group m-t-8" style="width: 300px">
            <div class="form-row">
                <div class="form-switch">
                    <label class="switch right">
                        <span class="label">{{ ('Ticket_Template_List.Archived_Items' | trans) | async}}</span>
                        <input type="checkbox" (change)="getNewTemplates($event)"/>
                        <span class="slider round"></span>
                    </label>
                </div>
            </div>
        </div>
        <cm-button data-icon="add"
                   style="float: right"
                   data-button-style="cta"
                   data-button-type="icon-text"
                   data-button-size="medium"
                   class="m-b-8"
                   id="ticketTemplateAddButton"
                   data-label="{{ ('Ticket_Template_List.Add_Button' | trans) | async}}" (click)="openTypeModal()">
        </cm-button>
    </div>
    <cm-dialog class="cm-dialog" #ticketTemplateTypeModal data-show-bottom-divider data-max-width="600px"
               data-max-height="600px">
        <div slot="header">
            <h5 slot="header" class="dialog-title">  {{ ('Ticket_Template_List.Select_Type' | trans) | async}}</h5>
            <ng-container *ngIf="ticketTemplateTypes && ticketTemplateTypes.length > 1">
                <div class="row">
                    <div class="ticket-template-type d-flex justify-content-between">
                        <ng-container *ngFor="let templateType of ticketTemplateTypes">
                            <div class="col p-r-8 p-l-8">
                                <button class="btn btn-ticket-template-type col"
                                        [ngClass]="{'btn-selected': selectedTicketTemplateType == templateType}"
                                        (click)="selectNewTicketTemplatePresetType(templateType)">
                                    <span>{{templateType}}</span>
                                </button>
                            </div>
                        </ng-container>
                    </div>
                </div>
                <hr class="m-t-32">
            </ng-container>
        </div>
        <div slot="body" class="form-row">
            <div class="card-wrapper list-view">
                <div class="card-container">
                    <div class="row m-t-24">
                        <ng-container *ngFor="let preset of ticketTemplatePresets; let i = index">
                            <div class="hoverPreset p-8"
                                 [ngClass]="{'col-lg-4': preset.ticket_template_type_id === 'A4', 'col-lg-6': preset.ticket_template_type_id !== 'A4'}">
                                <ng-container [ngSwitch]="preset.ticket_template_preset_type_id">
                                    <cm-ticketing-a4-default-1 *ngSwitchCase="'A4_DEFAULT_1'"
                                                               (click)="onTicketTemplateAddClick(preset.ticket_template_id)"></cm-ticketing-a4-default-1>
                                    <cm-ticketing-a4-default-2 *ngSwitchCase="'A4_DEFAULT_2'"
                                                               (click)="onTicketTemplateAddClick(preset.ticket_template_id)"></cm-ticketing-a4-default-2>
                                    <cm-ticketing-a4-custom [currentLang]="currentLang" *ngSwitchCase="'A4_CUSTOM'"
                                                            (click)="onTicketTemplateAddClick(null)"></cm-ticketing-a4-custom>
                                    <cm-ticketing-boca-default-1 *ngSwitchCase="'BOCA_DEFAULT_1'"
                                                                 (click)="onTicketTemplateAddClick(preset.ticket_template_id)"></cm-ticketing-boca-default-1>
                                    <cm-ticketing-boca-default-2 *ngSwitchCase="'BOCA_DEFAULT_2'"
                                                                 (click)="onTicketTemplateAddClick(preset.ticket_template_id)"></cm-ticketing-boca-default-2>
                                    <cm-ticketing-boca-custom [currentLang]="currentLang" *ngSwitchCase="'BOCA_CUSTOM'"
                                                              (click)="onTicketTemplateAddClick(null)"></cm-ticketing-boca-custom>
                                    <cm-ticketing-pvccard-default-1 *ngSwitchCase="'PVCCARD_DEFAULT_1'"
                                                                    (click)="onTicketTemplateAddClick(preset.ticket_template_id)"></cm-ticketing-pvccard-default-1>
                                    <cm-ticketing-pvccard-default-2 *ngSwitchCase="'PVCCARD_DEFAULT_2'"
                                                                    (click)="onTicketTemplateAddClick(preset.ticket_template_id)"></cm-ticketing-pvccard-default-2>
                                    <cm-ticketing-pvccard-custom [currentLang]="currentLang"
                                                                 *ngSwitchCase="'PVCCARD_CUSTOM'"
                                                                 (click)="onTicketTemplateAddClick(null)"></cm-ticketing-pvccard-custom>
                                </ng-container>
                            </div>
                        </ng-container>
                    </div>
                </div>
            </div>
        </div>
    </cm-dialog>
</ng-container>

<ng-container *ngIf="ticketTemplates$ | async as templates">
    <div class="row card-wrapper" id="template-list">
        <ng-container *ngFor="let ticketTemplate of ticketTemplatesSizedPerTwelve">
            <div class="col-xxl-4 col-xl-6 col-lg-4 col-md-6 col-xs-12">
                <div class="template-cards m-r-16">
                    <div class="card-preview">
                        <div class="skeleton-loading">
                            <iframe (load)="loadedIframe($event)" width="100%" height="100%"
                                    [src]="ticketTemplate.example">
                            </iframe>
                        </div>
                    </div>
                    <h5 class="card-title">
                        {{ ticketTemplate.name }}
                    </h5>
                    <div class="card-content">
                        <p>{{('Ticket_Template_List.Generic_Text' | trans) | async}} {{ticketTemplate.ticket_template_type_id}}</p>
                    </div>
                    <div class="card-footer">
                        <div class="row col-12 align-items-center">
                            <div class="col">
                                <cm-button data-label="{{('Ticket_template_list.Print_Button' | trans) | async}}"
                                           data-button-type="icon-text"
                                           data-icon="print"
                                           data-button-style="primary"
                                           (click)="renderTemplate(ticketTemplate.url)">
                                </cm-button>
                            </div>

                            <ng-container *ngIf="!ticketTemplate.is_default">
                                <div class="col">
                                    <cm-button data-label="{{('Ticket_template_list.Edit_Button' | trans) | async}}"
                                               data-button-type="icon-text"
                                               data-icon="edit"
                                               data-button-style="primary"
                                               class="edit-ticket-template"
                                               (click)="onTicketTemplateEditClick(ticketTemplate)">
                                    </cm-button>
                                </div>

                                <div class="card-menu">
                                    <cm-context-menu (click)="$event.stopPropagation()">
                                        <ng-container *ngIf="ticketTemplate.is_visible;">
                                            <cm-option data-icon="archive"
                                                       [attr.data-display]="(('Ticket_Template_List.Archive_Template' | trans) | async)"
                                                       (cm-option-clicked)="onDeleteTicketTemplateClick(ticketTemplate)"></cm-option>
                                        </ng-container>
                                        <ng-container *ngIf="!ticketTemplate.is_visible;">
                                            <cm-option data-icon="add"
                                                       [attr.data-display]="(('Ticket_Template_List.Activate_Template' | trans) | async)"
                                                       (cm-option-clicked)="onActivateTicketTemplateClick(ticketTemplate)"></cm-option>
                                        </ng-container>
                                    </cm-context-menu>
                                </div>
                            </ng-container>
                        </div>
                    </div>
                </div>
            </div>
        </ng-container>
    </div>
</ng-container>
<ng-container *ngIf="isFetchingRecords">
    <div class="load-indicator">
        <cm-indicator></cm-indicator>
    </div>
</ng-container>
