<div class="toggle-sidebar">
    <span class="cm-icon cm-icon-open-vertical small icon-hover" aria-hidden="true" (click)="toggleSidebar()"></span>
</div>

<div class="toggle-fullscreen">
    <span class="cm-icon icon-hover fullscreen-button" aria-hidden="true"
          [class.cm-icon-maximize]="!isFullscreen"
          [class.cm-icon-minimize]="isFullscreen"
          (click)="toggleFullscreen()"></span>
</div>

<div class="venue-section-list" (click)="openSidebar()">
    <ng-container *ngIf="sidebarState === 'VENUE_SECTION_LIST'">
        <div class="row">
            <div class="col-10">
                <div class="header form-group">
                    <div class="form-row">
                        <div class="form-input">
                            <cm-input placeholder="Search"
                                      [attr.value]="venueSectionFilter"
                                      (input)="setVenueSectionFilter($event.target.value)">
                            </cm-input>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-2">
                <cm-context-menu class="venue-section-list-size-context-menu">
                    <span class="cm-icon"
                          [class.cm-icon-rowheight-low]="venueSectionListSize === 'COMPACT'"
                          [class.cm-icon-rowheight-high]="venueSectionListSize === 'DETAILED'"
                          aria-hidden="true"
                          slot="icon">
                    </span>
                    <cm-option
                        data-icon="rowheight-low"
                        data-display="Compact"
                        (cm-option-clicked)="setVenueSectionListSize('COMPACT')">
                    </cm-option>
                    <cm-option
                        data-icon="rowheight-high"
                        data-display="Detailed"
                        (cm-option-clicked)="setVenueSectionListSize('DETAILED')">
                    </cm-option>
                </cm-context-menu>
            </div>
        </div>
        <ng-container *ngFor="let ring of venue?.sections | venueSectionFilter:venueSectionFilter">
            <div class="ring">
                <div class="ring-header">
                    {{ ring.name }}
                </div>
                <div class="ring-sections">
                    <ng-container *ngFor="let venueSection of ring.sections">
                        <app-venue-section-list-item [venueSection]="venueSection"
                                                     [mode]="venueSectionListSize"
                                                     [selected]="venueSection.id === selectedVenueSection?.id"
                                                     (click)="setVenueSection(venueSection.id)">
                        </app-venue-section-list-item>
                    </ng-container>
                </div>
            </div>
        </ng-container>
    </ng-container>

    <ng-container *ngIf="sidebarState === 'VENUE_SECTION_DETAIL'">
        <div class="m-b-16">
            <div class="row">
                <div class="col-10 p-16">
                    <cm-button
                        data-icon="left"
                        [attr.data-label]="venue.name"
                        data-button-style="secondary"
                        data-button-type="icon-text"
                        data-button-size="small"
                        (click)="clearSelectedVenueSection()">
                    </cm-button>
                    <h3 class="sidebar-venue-section-title"
                        [class.skeleton-loading]="!selectedVenueSection">{{ selectedVenueSection?.name }}</h3>
                </div>
            </div>
        </div>

        <div class="p-16">
            <div class="sidebar-content">
                <div class="sidebar-content-section">
                    <div class="legend">
                        <ng-container *ngFor="let legendItem of legendItems">
                            <app-seat-map-legend-item
                                [color]="legendItem.color">{{ legendItem.label }}</app-seat-map-legend-item>
                        </ng-container>
                    </div>
                </div>

                <ng-container *ngIf="blockades?.length && selectedVenueSection?.isSeated">
                    <div class="sidebar-content-section">
                        <h4>{{ 'General.Blockades' | trans }}</h4>
                        <ul>
                            <ng-container *ngFor="let blockade of blockades">
                                <li class="blockade-list-item"
                                    [class.selected]="blockade.id === selectedBlockadeId"
                                    (click)="onBlockadeClick(blockade)">
                                    <span class="cm-icon cm-icon-sending small icon" aria-hidden="true"
                                          [style.color]="blockade.color"></span> {{ blockade.name }}
                                </li>
                            </ng-container>
                        </ul>
                    </div>
                </ng-container>
            </div>
        </div>
    </ng-container>
</div>

<div class="venue-section-detail" [class.disabled]="(loadingDetails$ | async).isLoading">
    <ng-container *ngIf="venueSection$ | async as venueSection">
        <ng-container *ngIf="venueSection.isSeated">
            <app-seatmap
                [eventId]="event?.id"
                [subscriptionTypeId]="subscriptionType?.id"
                [venueSection]="venueSection"
                         [selectedVenueSectionSeats]="selectedVenueSectionSeatIds$ | async"
                         [blockades]="blockades"
                         [options]="seatMapOptions"
                         [tool]="tool"
                         (venueSectionSeatClick)="onVenueSectionSeatsSelected([$event])"
                         (venueSectionSeatsSelected)="onVenueSectionSeatsSelected($event)"
                         (venueSectionSeatsDeselected)="onVenueSectionSeatsDeselected()">
            </app-seatmap>
        </ng-container>

        @if (!venueSection.isSeated) {
            @if (event) {
                <div class="venue-section-seat-setting-button">
                    <cm-button
                               data-button-type="icon"
                               data-icon="switch"
                               data-custom-classes="rotate-90"
                            (click)="openVenueSectionSeatSettingDialog(venueSection.seats)"
                            [cm-tooltip]="('Venue_Detail.Capacity' | trans)">
                    </cm-button>
                </div>
            }

            <app-venue-section [event]="event"
                               [subscriptionType]="subscriptionType"
                               [venueSection]="venueSection">
            </app-venue-section>
        }
    </ng-container>

    <ng-container *ngIf="(loadingDetails$ | async).isLoading">
        <div class="loading">
            <cm-indicator></cm-indicator>
        </div>
    </ng-container>

    <ng-container *ngIf="!selectedVenueSection && !(loadingDetails$ | async).isLoading">
        <cm-image data-type="empty" data-min-height="240" class="d-block"></cm-image>
        <div class="text-center">
            <h3>{{ 'Venue_Section_Master_Detail.Select_Venue_Section' | trans }}</h3>
        </div>
    </ng-container>
</div>

<app-blockade-action-footer [eventId]="event ? event.id : null"
                            [venueId]="venue.id"
                            [updatedBlockades]="updatedBlockades$ | async"
                            (blockadesSaved)="onBlockadesSaved()"
                            (changesDiscarded)="onUpdatedBlockadesDiscarded()">
</app-blockade-action-footer>
