import {Inject, Injectable} from '@angular/core';
import {DOCUMENT} from '@angular/common';

@Injectable({
    providedIn: 'root'
})
export class DashboardService {
    constructor(@Inject(DOCUMENT) private document: Document) {
    }

    setFocusMode(isFocusMode: boolean = true): void {
        this.document.body.classList.toggle('focus', isFocusMode);
    }

    openSidenav(): void {
        (document.querySelector('cm-sidenav') as any).openSidenav();
    }

    closeSidenav(): void {
        (document.querySelector('cm-sidenav') as any).closeSidenav();
    }
}
