import {Injectable} from '@angular/core';
import {
    HttpErrorResponse,
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
    HttpResponse
} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {Observable, throwError} from 'rxjs';
import {PlatformService} from '../../shared/services/platform-service/platform.service';
import {catchError, tap} from 'rxjs/operators';
import {Router} from '@angular/router';
import {LocaleService} from '../services/locale.service';
import {OrderManagementService} from '../../shared/services/order-service/order-management.service';
import {HttpStatusCode} from '../../shared/enums/util/http-status-code';
import {MaintenanceModeService} from '../../shared/services/maintenance-mode.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

    constructor(private localeService: LocaleService,
                private platformService: PlatformService,
                private router: Router,
                private orderManagementService: OrderManagementService,
                private maintenanceModeService: MaintenanceModeService) {
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        let headers = {};

        const addTechnicalLinkHeader = !request.headers.has('X-TF-TECHNICALINKGUID');
        const technicalLink = addTechnicalLinkHeader
            ? this.platformService.getTechnicalLinkId()
            : request.headers.get('X-TF-TECHNICALINKGUID');


        if (technicalLink) {
            headers = {
                'X-TF-TECHNICALINKGUID': technicalLink,
                'X-CM-SSO-ACCOUNTGUID': (window as any).getCmSSO().UserData.AccountGuid
            };
        }

        if (request.params.has('includePersonGuid')) {
            const personGuid = (window as any).getCmSSO().UserData.PersonGuid;

            if (personGuid) {
                headers['X-CM-SSO-PERSONGUID'] = personGuid;
            }

            request.params.delete('includePersonGuid');
        }


        request = request.clone({
            setHeaders: headers,
            setParams: {
                language: this.localeService.getShortLanguage().toUpperCase()
            }
        });

        return next.handle(request).pipe(
            tap(response => {
                this.checkForMaintenance(response);
            }),
            catchError((error: HttpErrorResponse) => {
                const url = window.location.href;

                if (error.error.code === 40029 || error.error.code === 40098) {
                    this.orderManagementService.unsetOrder();
                }

                const isLibRequest = request.url.includes(environment.CM_ORDER_DISTRIBUTION_URL);
                if (error.status === 404 && !request.params.has('ignoreRedirect') && !isLibRequest) {
                    this.router.navigate(['status/not-found']);
                }

                const errorWithRequest = {...error, request, url};

                return throwError(errorWithRequest);
            })
        );
    }

    private checkForMaintenance(response: HttpEvent<any>): void {
        if (this.maintenanceModeService.canRedirectToMaintenancePage()) {
            return;
        }

        if (response instanceof HttpResponse && response.body?.code === HttpStatusCode.MAINTENANCE_MODE) {
            this.maintenanceModeService.isNavigatingToMaintenancePage = true;
            return void this.router.navigate(['status/scheduled-maintenance']);
        }
    }
}
